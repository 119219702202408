import React from "react"


import Layout from "../components/layout"
import HeroInlineSVG from "../images/hero-image.inline.svg"
import SEO from "../components/seo"
import styled, { keyframes } from 'styled-components'



const float = keyframes`
0% { opacity: 0.8; 
    transform: translateY(0);}
25% {
  opacity: 0.3; 
    transform: translateY(-20px);
  
}
100% { opacity: 0;
      transform: translateY(-40px);}
`

const smile = keyframes`
    0% {
      fill: #262626;
    }
    25% {
      fill: #fff;
    }
    75% {
      fill: #fff
    }
    100% {
      fill: #262626;
    }

`

const look = keyframes`
    0% {
      transform: translateX(-1px);
    }

    25% {
      transform: translateX(-2px);

    }

    75% {
      transform: translateX(-2px);
    }

    100% {
      transform: translateX(0px);
    }
`



const StyledHero = styled(HeroInlineSVG)`
    
  

    .hero-image_inline_svg__steam {
      animation: ${float} infinite 4s ease-out ;
      
    }

    .hero-image_inline_svg__lips {
      animation: ${smile}  4s ease-out 3s;
      
    }

    .hero-image_inline_svg__eyes {
      animation: ${look}  4s linear 2s;
      
    }

    @media only screen and (max-width : 660px) {

            margin-top: 5.5em;
        
      }

   
`





const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <div><h1 style={{margin:'auto',height:'96px'}}className="expand">Hi, My name is Tonye.I'm a Front-end developer and Designer.
    </h1></div>
    <figure style={{marginTop:'1.5em'}}>
      <StyledHero />
    </figure>
   
  </Layout>
)

export default IndexPage
